import gql from 'graphql-tag';

export const GET_PRESIGNED_URLS = gql`
  query preSignedUrls($fileNames: [String], $feature: String!, $id: ID!) {
    preSignedUrls(fileNames: $fileNames, feature: $feature, id: $id)
  }
`;

export const GET_SIGNED_URLS = gql`
  query signedUrls($fileNames: [String], $feature: String!, $id: ID!) {
    signedUrls(fileNames: $fileNames, feature: $feature, id: $id)
  }
`;

export const SCAN_FILES = gql`
  query scanFiles($fileNames: [String], $feature: String!, $id: ID!) {
    scanFiles(fileNames: $fileNames, feature: $feature, id: $id)
  }
`;

export const REMOVE_FILES = gql`
  mutation removeFiles($fileNames: [String]!, $feature: String!, $id: ID!) {
    removeFiles(fileNames: $fileNames, feature: $feature, id: $id)
  }
`;
