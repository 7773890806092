import { createMuiTheme } from '@material-ui/core/styles';

export const blue = {
  50: '#e3effb',
  100: '#bad6f5',
  200: '#8cbbee',
  300: '#5e9fe7',
  400: '#3c8be1',
  500: '#1976dc',
  600: '#166ed8',
  700: '#1263d3',
  800: '#0e59ce',
  900: '#0846c5',
  A100: '#eff3ff',
  A200: '#bccfff',
  A400: '#89aaff',
  A700: '#6f97ff',
};

export const purple = {
  50: '#f2e5f1',
  100: '#ddbfdd',
  200: '#c794c6',
  300: '#b169af',
  400: '#a0489d',
  500: '#8f288c',
  600: '#872484',
  700: '#7c1e79',
  800: '#72186f',
  900: '#600f5c',
  A100: '#ff96f9',
  A200: '#ff63f7',
  A400: '#ff30f4',
  A700: '#ff16f2',
};

export const indigo = {
  50: '#E8EAF6',
  100: '#C5CAE9',
  200: '#9FA8DA',
  300: '#7986CB',
  400: '#5C6BC0',
  500: '#3F51B5',
  600: '#3949AB',
  700: '#303F9F',
  800: '#283593',
  900: '#1A237E',
  A100: '#8C9EFF',
  A200: '#536DFE',
  A400: '#3D5AFE',
  A700: '#304FFE',
};

export const grey = {
  25: '#eaeaea',
  50: '#e8e8e8',
  100: '#c6c6c6',
  200: '#a0a0a0',
  300: '#797979',
  400: '#5d5d5d',
  500: '#404040',
  600: '#3a3a3a',
  700: '#323232',
  800: '#2a2a2a',
  900: '#1c1c1c',
  A100: '#f07777',
  A200: '#eb4949',
  A400: '#ff0202',
  A700: '#e70000',
};

export const yellow = {
  50: '#fefce4',
  100: '#fdf8bb',
  200: '#fcf38e',
  300: '#faee60',
  400: '#f9eb3e',
  500: '#f8e71c',
  600: '#f7e419',
  700: '#f6e014',
  800: '#f5dd11',
  900: '#f3d709',
  A100: '#ffffff',
  A200: '#fffce9',
  A400: '#fff5b6',
  A700: '#fff29c',
};

export const red = {
  50: '#f9e1e4',
  100: '#f1b3bb',
  150: '#e13c2f',
  200: '#e8818d',
  300: '#de4e5f',
  400: '#d7283d',
  500: '#d0021b',
  600: '#cb0218',
  700: '#c40114',
  800: '#be0110',
  900: '#b30108',
  A100: '#ffdcdd',
  A200: '#ffa9aa',
  A400: '#ff7678',
  A700: '#ffeef0',
};

export const orange = {
  50: '#fdebe5',
  100: '#fbcebf',
  200: '#f8ad94',
  300: '#f58c69',
  400: '#f27349',
  500: '#f05a29',
  600: '#ee5224',
  700: '#ec481f',
  800: '#e93f19',
  900: '#e52e0f',
  A100: '#ffffff',
  A200: '#ffe2de',
  A400: '#ffb4ab',
  A700: '#ff9e91',
};

export const teal = {
  50: '#E0F2F1',
  100: '#B2DFDB',
  200: '#80CBC4',
  300: '#4DB6AC',
  400: '#26A69A',
  500: '#009688',
  600: '#00897B',
  700: '#00796B',
  800: '#00695C',
  900: '#004D40',
  A100: '#A7FFEB',
  A200: '#64FFDA',
  A400: '#1DE9B6',
  A700: '#00BFA5',
};

export const green = {
  50: '#ebf3e1',
  100: '#cde2b5',
  200: '#accf84',
  300: '#8abb52',
  400: '#71ad2d',
  500: '#589e08',
  600: '#509607',
  700: '#478c06',
  800: '#3d8204',
  900: '#2d7002',
  A100: '#bdff9f',
  A200: '#99ff6c',
  A400: '#76ff39',
  A700: '#e6ffed',
  main: '#589e08',
};

const theme = createMuiTheme({
  colors: {
    blue,
    green,
    grey,
    indigo,
    orange,
    purple,
    red,
    teal,
    yellow,
  },
  typography: {
    header: {
      fontSize: '36px',
      fontWeight: '500',
    },
    color: grey[800],
    useNextVariants: true,
  },
  palette: {
    action: {
      chevron: grey[400],
      hover: grey[100],
      main: grey[400],
    },
    primary: {
      light: blue[200],
      main: blue[500],
      dark: blue[800],
    },
    secondary: {
      light: purple[400],
      main: purple[500],
      dark: purple[800],
    },
    danger: { main: red[800] },
    warning: { main: orange[600] },
    subtleWarning: { main: yellow[200] },
    success: { main: green[500] },
    white: { main: '#FFF' },
    green,
    grey: {
      subheader: grey[25],
      light: grey[50],
      medium: grey[300],
      main: grey[500],
      dark: grey[800],
    },
    borderColor: { main: grey[50] },
    completed: { main: green[900] },
    black: { main: '#000' },
    info: { main: blue[500] },
  },
  mixins: {
    title: {
      fontSize: '18px',
      fontWeight: 500,
    },
    formLabel: {
      fontSize: '12px',
    },
    sidebarMenu: {
      content: {},
      subheader: {
        color: grey[50],
        backgroundColor: grey[500],
        paddingLeft: '18px',
        fontWeight: '300',
        textTransform: 'uppercase',
        lineHeight: '30px',
      },
      button: {
        '&:hover': {
          backgroundColor: grey[800],
        },
      },
      active: {
        backgroundColor: grey[800],
      },
      keyboardFocused: {
        backgroundColor: grey[800],
      },
      listItem: {
        '&.MuiLink-underlineHover:hover': {
          underline: 'none !important',
        },
        paddingLeft: '18px',
      },
      listItemIcon: {
        marginRight: '8px',
        minWidth: '32px',
      },
      text: {
        color: grey[50],
        fontSize: '14px',
      },
      icon: {
        fontSize: '22px',
        color: 'white',
      },
    },
    helpSidebar: {
      contentShiftLeft: {
        '@media (max-width: 600px), (max-width: 812px) and (orientation: landscape)': {
          marginRight: 0,
        },
      },
      content: {
        width: 320,
        position: 'absolute',
        padding: 0,
        '@media (max-width: 600px), (max-width: 812px) and (orientation: landscape)': {
          width: 'auto',
          left: 56,
        },
      },
    },
    question: {
      fontSize: 30,
      container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 4,
      },
      childContainer: {
        width: '50%',
      },
      expansionPanel: {
        boxShadow: 'none',
      },
    },
    profile: {
      chip: {
        marginRight: '8px',
        marginBottom: '4px',
        borderRadius: 0,
      },
      mediumFontSize: {
        fontSize: 'medium',
        color: '#000',
      },
      icon: {
        color: 'white',
      },
      list: {
        description: {
          padding: '16px',
        },
        grid: {
          margin: 0,
          paddingLeft: '8px',
          paddingRight: '8px',
          width: '100%',
          wordBreak: 'break-all',
        },
        icon: {
          color: grey,
          display: 'block',
          textAlign: 'right',
        },
        noContent: {
          padding: '8px',
        },
        panel: {
          margin: 20,
        },
      },
      table: {
        tableRow: {
          cursor: 'pointer',
        },
        tableWrapper: {
          overflow: 'auto',
        },
      },
    },
    list: {
      listItem: {
        nameCol: {
          width: 300,
        },
        emailCol: {
          width: 300,
        },
      },
      fullWidth: {
        width: '100%',
      },
    },
    text: {
      ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      wrap: {
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      },
      disabled: {
        opacity: 0.5,
      },
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        height: 1,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 400,
      },
      h4: {
        fontSize: '1.4rem',
      },
      h5: {
        fontSize: '1.2rem',
      },
    },
  },
});

theme.mixins.question.textField = {
  marginRight: theme.spacing(1),
};

theme.mixins.question.textLabel = {
  ...theme.typography.subtitle1,
  fontSize: '1rem',
  fontWeight: 400,
  marginRight: theme.spacing(1),
  paddingTop: 12,
  whiteSpace: 'pre-line',
  color: theme.palette.black.main,
};

theme.mixins.question.groupLabel = {
  ...theme.typography.subtitle1,
  padding: '10px 0',
};
theme.mixins.helpFont = {
  fontSize: '12px',
};

theme.mixins.question.dependentLabel = {
  ...theme.typography.body2,
  paddingTop: 10,
};

theme.mixins.question.optional = {
  ...theme.typography.caption,
};

theme.mixins.formError = {
  color: theme.palette.danger.main,
  fontSize: '12px',
};

theme.mixins.question.datePicker = {
  paddingBottom: theme.spacing(1),
};

theme.mixins.list.headerText = {
  ...theme.mixins.text.ellipsis,
  fontWeight: 500,
};

theme.mixins.list.column = {
  paddingRight: theme.spacing(0.5, 1),
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(0.25, 1)} !important`,
  },
};

theme.mixins.list.item = {
  minHeight: theme.spacing(6),
  alignItems: 'flex-start',
};

theme.mixins.list.itemIcon = {
  fontSize: '2em',
};

theme.mixins.list.itemButtonRoot = {
  padding: theme.spacing(0.5),
};

theme.mixins.list.itemIconRoot = {
  alignItems: 'flex-start',
  minWidth: theme.spacing(5),
};

theme.mixins.list.itemLink = {
  padding: 0,
  textTransform: 'none',
  textAlign: 'left',
};

theme.overrides.MuiLink = {
  root: {
    color: theme.palette.primary.dark,
  },
};

export default theme;
