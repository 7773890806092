import { useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';

import { TENANTS_QUERY } from '../graphql/tenants.query';

function getTenantList() {
  return orderBy(
    this.TENANTS.map(({ tenantCode, name }) => ({
      tenantCode,
      name,
    })),
    'tenantCode',
  );
}

function getTenant(tenantCode) {
  return this.TENANTS.find((c) => c.tenantCode === tenantCode);
}

function getCampus(campusCode) {
  const tenant = this.TENANTS.find((t) => t.campusList.some((c) => c.campusCode === campusCode));
  return tenant ? tenant.campusList.find((c) => c.campusCode === campusCode) : null;
}

function getTenantByCampusCode(campusCode) {
  return this.TENANTS.find((t) => t.campusList.some((c) => c.campusCode === campusCode));
}

function getCampusesByTenantCode(tenantCode) {
  const tenant = this.TENANTS.find((c) => c.tenantCode === tenantCode);
  return orderBy(tenant ? tenant.campusList : [], 'name');
}

function getInstitutionByCampusCode(campusCode) {
  const tenant = this.getTenantByCampusCode(campusCode);
  const campus = this.getCampus(campusCode);
  if (!campus) return null;

  return tenant.campusList.filter((c) => c.institution && c.institution === campus.institution);
}

export default function useTenants() {
  const { data } = useQuery(TENANTS_QUERY);

  return {
    TENANTS: data?.tenants || [],
    getTenant,
    getTenantList,
    getCampus,
    getCampusesByTenantCode,
    getInstitutionByCampusCode,
    getTenantByCampusCode,
  };
}
