import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query LoadProfile {
    profile {
      userId
      firstName
      lastName
      email
      phone
      idps
      tenantCode
      campusCode
      features
      groups {
        id
        name
        isOwner
        isDelegate
        members {
          userId
          employeeId
          firstName
          lastName
          roles
        }
        tags
      }
      roles {
        role
        campusCode
        tenantCode
        groupIds
        type
      }
      relationships
      inventoriesWithReadAccess
    }
    rolePermissions
  }
`;

export const PERSON_SUMMARY = gql`
  query PersonSummary($id: ID!) {
    personSummary(id: $id) {
      firstName
      lastName
      email
      userId
      campusCode
      tenantCode
    }
  }
`;

export const USER_DETAIL_FOR_ADMIN = gql`
  query LoadUser($id: ID!) {
    personByUserId(id: $id) {
      userId
      firstName
      lastName
      email
      phone
      idps
      tenantCode
      campusCode
      groups {
        id
        name
        members {
          userId
          firstName
          lastName
          roles
        }
        tags
      }
      roles {
        role
        campusCode
        tenantCode
        groupIds
      }
      relationships
    }
  }
`;
