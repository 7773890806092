/* eslint-disable max-len */
import { RELATIONSHIP_LABEL } from '@rss/common';

const {
  EDGE: { DELEGATE, MEMBER, NURSE_MANAGER, OWNER, SAFETY_COORDINATOR },
  NODE: { COLLECTION, FLOOR, GROUP, ROOM },
} = RELATIONSHIP_LABEL;

export const profileConfig = {
  COLLECTION: {
    DESCRIPTION: 'Collections allow safety coordinators to view groups and documents within the collection.',
    ADMIN_CREATE_DESCRIPTION:
      'Collections allow safety coordinators to view groups and documents within the collection.',
    SAFETY_COORDINATOR: 'Safety Coordinators will have view access to your group and documents.',
    GROUP: {
      ADD: 'Groups added to collections will be viewable by the Safety Coordinator associated with the collection.',
      REMOVE:
        'Safety Coordinators for this collection will no longer be able to view information regarding this group once removed from the collection.',
    },
    roles: [SAFETY_COORDINATOR],
    defaultRole: SAFETY_COORDINATOR,
    includeUnderAccountManagement: true,
    createAccess: false,
    locationType: null,
    relatedOrgType: GROUP,
    memberAlias: 'Safety Coordinator',
  },
  DEPARTMENT: {
    createAccess: false,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
  },
  GROUP: {
    canAdminCreateForUser: true,
    createAccess: true,
    DESCRIPTION: 'Groups are used to manage permissions and access to documents and inventories.',
    CREATE_DESCRIPTION:
      'Only Principal Investigators and Responsible Persons should create groups. People added to the group can have access to documents linked to the group.',
    ADMIN_CREATE_DESCRIPTION: 'Create a group on behalf of someone by searching for them and entering a group name.',
    DELEGATE: 'Can add/edit/remove group members and locations.',
    MEMBER: 'Can view group members and locations.',
    filterMetaData: { tags: ['LHA'] },
    roles: [DELEGATE, MEMBER],
    defaultRole: MEMBER,
    includeUnderAccountManagement: true,
    locationType: ROOM,
    relatedOrgType: COLLECTION,
    memberAlias: 'Member',
    hideMetaData: true,
    appList: [
      { code: 'assessment', displayName: 'Assessment' },
      { code: 'chemicals', displayName: 'Chemicals' },
      { code: 'inspect', displayName: 'Inspect' },
      { code: 'radiation', displayName: 'Radiation' },
      { code: 'waste', displayName: 'Waste' },
    ],
    enableTransfer: true,
    showTrainingLink: true,
  },
  PROGRAM: {
    DESCRIPTION: 'Permissions: Can view, manage programs and perform inspection.',
    roles: [],
    createAccess: false,
    hideInProfileIfNoData: true,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
  },
  UNIT: {
    DESCRIPTION: 'Units are used to manage permissions and access to relevant documents and inventories.',
    ADMIN_CREATE_DESCRIPTION: 'Units are used to manage permissions and access to relevant documents and inventories.',
    NURSE_MANAGER: 'Permissions: perform assessments, resolve survey/round findings, and completing plans.',
    MEMBER: 'Permissions: Can view unit details.',
    roles: [NURSE_MANAGER, MEMBER],
    defaultRole: MEMBER,
    includeUnderAccountManagement: true,
    createAccess: false,
    locationType: FLOOR,
    relatedOrgType: null,
    memberAlias: 'Member',
  },
  'ASSESSMENT::LAB_HAZARD': {
    MEMBER: 'Can view Lab Hazard Assessment.',
    roles: [],
  },
  'UNIT_RISK::ASSESSMENT': {
    OWNER: 'Owners of the Unit Risk Assessment can view, edit and submit hazards stated in the assessment.',
    roles: [OWNER],
  },
  'ASSESSMENT::BUA': {
    MEMBER: 'Can view BUA.',
    roles: [],
  },
};

export const reminderConfig = {
  APPLICATION_NAME: 'PROFILE',
  GROUP: {
    LABEL_TYPE: 'NEW_GROUP',
    TITLE: 'Review new groups',
  },
};

export const rolesDescription = {
  BIOSAFETY_ADMIN:
    'Biosafety Admins are able to administer and assist with approvals for BUAs within their organization.',
  IBC_MEMBER: 'IBC Members review BUAs for approval within their organization.',
  LAB_HAZARD_ADMIN: 'Lab Hazard Admins manage and administer Lab Hazard Assessments.',
  PPE_COORDINATOR:
    'PPE Coordinators manage Personal Protective Equipment for individuals and can manage PPE inventories.',
  UNIT_RISK_ADMIN: 'Unit Risk Admins manage and assign Unit Risk Assessments.',
  UNIT_MANAGER: 'Unit Managers manage and assign Nurse Managers.',
};

export const EXTERNAL_DESCRIPTION = 'Please contact your account manager to update your name and email address.';

export const roleTypes = {
  INTERNAL: 'INTERNAL',
  INTERNAL_EXTERNAL: 'INTERNAL-EXTERNAL',
};

export const MAX_ITEMS = 5;
