import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { updateTimeoutTime, SignOut } from '../shared/auth.helper';

const useStyles = makeStyles({
  box: {
    minWidth: 325,
  },
});

export default function CountDownDialog({ duration, onClose }) {
  const classes = useStyles();
  const [timeRemaining, setTimeRemaining] = useState(duration);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((d) => (d > 0 ? d - 1 : d));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (timeRemaining <= 0) {
    localStorage.setItem(IDLE_KEY.IDLE_SIGNOUT_KEY, true);
    SignOut();
  }

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open>
      <DialogTitle>{IDLE_TIMEOUT.IDLE_WARNING_HEADING}</DialogTitle>
      <DialogContent className={classes.box}>
        <DialogContentText>{IDLE_TIMEOUT.IDLE_WARNING_MESSAGE(timeRemaining)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            localStorage.removeItem(IDLE_KEY.IDLE_SIGNOUT_KEY);
            SignOut();
          }}>
          {IDLE_TIMEOUT.IDLE_SIGNOUT_BUTTON}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.removeItem(IDLE_KEY.IDLE_SIGNOUT_KEY);
            updateTimeoutTime(Date.now());
            onClose();
          }}>
          {IDLE_TIMEOUT.IDLE_STAY_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CountDownDialog.propTypes = {
  duration: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
