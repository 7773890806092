import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import config from './config';
import { useSecurity } from './hooks';
import { getImpersonateId } from './shared/helper';

const httpLink = createHttpLink({ uri: `${config.GRAPH_URL}/graphql`, credentials: 'include' });

const authMiddleware = setContext(() => {
  const context = { headers: {} };

  const userId = getImpersonateId();
  if (userId) {
    context.headers['impersonate-id'] = userId;
  }

  return context;
});

export const errorLink = onError(({ networkError }) => {
  const { browserSecurity } = useSecurity();
  if (networkError && networkError.statusCode === 401) {
    browserSecurity.login();
  }
});

export const ReadOnlyClient = new ApolloClient({
  link: createHttpLink({ uri: `${config.GRAPH_URL}/read-only`, credentials: 'include' }),
  cache: new InMemoryCache(),
});

export const ReadWriteClient = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});

export default ReadWriteClient;
