import { useQuery } from '@apollo/react-hooks';
import { PROFILE_QUERY } from '../graphql/profile.query';

export default function useProfile() {
  const { loading, error, data, refetch } = useQuery(PROFILE_QUERY, { notifyOnNetworkStatusChange: true });

  return {
    loading,
    error,
    profile: data?.profile,
    rolePermissions: data?.rolePermissions,
    refetch,
  };
}
