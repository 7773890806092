import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';

import config from '../config';
import useSecurity from '../hooks/useSecurity';

function getTimeout() {
  return JSON.parse(localStorage.getItem(IDLE_KEY.IDLE_TIMEOUT_KEY)) || {};
}

function getIdleMinutes() {
  return JSON.parse(localStorage.getItem(IDLE_KEY.IDLE_MINUTES_KEY));
}

export function isIdleSession(idleTimeInMinutes) {
  if (!idleTimeInMinutes) {
    return false;
  }

  const { time: timeoutTime } = getTimeout();
  if (!timeoutTime) {
    return false;
  }

  const isIdle = Date.now() > new Date(timeoutTime).getTime();
  if (isIdle) {
    localStorage.setItem(IDLE_KEY.IDLE_SIGNOUT_KEY, true);
  }

  return isIdle;
}

export function shouldShowWarning() {
  const { time: timeoutTime } = getTimeout();
  if (!timeoutTime) {
    return false;
  }
  const shouldShow = Date.now() > new Date(timeoutTime).getTime() - IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES * 60000;

  if (shouldShow) {
    localStorage.setItem(IDLE_KEY.IDLE_SIGNOUT_KEY, true);
  }

  return shouldShow;
}

export function updateTimeoutTime() {
  const idleMinutes = getIdleMinutes();
  if (!idleMinutes) {
    return;
  }
  const timestamp = Date.now() + idleMinutes * 60000;

  const activeTime = getTimeout();
  localStorage.setItem(
    IDLE_KEY.IDLE_TIMEOUT_KEY,
    JSON.stringify({ ...activeTime, id: activeTime.id || timestamp, time: new Date(timestamp) }),
  );
}

export function SignOut() {
  localStorage.removeItem(IDLE_KEY.IDLE_MINUTES_KEY);
  localStorage.removeItem(IDLE_KEY.IDLE_TIMEOUT_KEY);
  localStorage.removeItem(config.SELECTED_IDP_KEY);

  const { browserSecurity } = useSecurity();
  browserSecurity.logout('/');
}
