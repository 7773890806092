import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const RssLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress color="secondary" thickness={2} />
    </div>
  );
};

export default RssLoader;
