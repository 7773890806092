import { ANALYTICS_ROLE, NODE_TYPE, RELATIONSHIP_LABEL, RelationshipHelper } from '@rss/common';
import { ASSESSMENT_STATUS } from '@risk-and-safety/assessment-v2-common';
import cookie from 'cookie';
import { saveAs } from 'file-saver';
import { get, isEqual, pull, sortBy, startCase, toLower, toUpper, uniqWith } from 'lodash';
import * as moment from 'moment';
import config from '../config';
import { profileConfig } from './profile-config';

const {
  EDGE: {
    BSAS_ADMIN,
    CLI_ADMIN,
    EHS_DEPARTMENT_ADMIN,
    IBC_MEMBER,
    LOTO_PROCEDURES_TEMPLATE_ADMIN,
    NFPA_ADMIN,
    PPE_COORDINATOR,
    SPH_PROCEDURES_TEMPLATE_ADMIN,
    WPVP_PROCEDURES_TEMPLATE_ADMIN,
  },
  NODE,
} = RELATIONSHIP_LABEL;

const { LAB_HAZARD, BUA, UNIT_RISK } = NODE;

export const getHeaders = async () => {
  const headers = {};
  const userId = cookie.parse(document.cookie)[config.SPOOF_USER_KEY] || null;
  if (userId) {
    headers['impersonate-id'] = userId;
  }

  return headers;
};

export const fetchData = async (url, options = {}) => {
  const headers = await getHeaders();
  return fetch(url, {
    ...options,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  });
};

export const getPeopleFromRelationships = (relationships) =>
  relationships
    .filter((r) => r.type === NODE_TYPE.PERSON)
    .map((member) => ({
      _id: member._id,
      userId: member.nodeId,
      firstName: member.label.firstName,
      lastName: member.label.lastName,
      role: member.role,
    }))
    .sort((member) => member.lastName);

export const getRoomsFromRelationships = (relationships) =>
  relationships
    .filter((r) => r.type === NODE_TYPE.ROOM)
    .map((member) => ({
      _id: member._id,
      roomId: member.nodeId,
      buildingName: member.label.buildingName,
      roomNumber: member.label.roomNumber,
    }));

export const exportAsPDF = async (title, documentId = 'root', url = null) => {
  const filename = `${title.replace(/\s/g, '_')}__${new Date().toLocaleDateString().replace(/\//g, '_')}.pdf`;
  let response = null;
  if (documentId) {
    const body = document.getElementById(documentId).innerHTML.replace(/src="\//g, `src="${window.location.origin}/`);
    const head = document.head.innerHTML.replace(/url\(\//g, `url(${window.location.origin}/`);
    const template = `
      <!DOCTYPE html>
        <html lang="en">
        <head>
          ${head}
        </head>
        <body>${body}</body>
        </html>
      `;
    response = await fetch(`${config.PRINTABLE_URL}/pdf/from/html`, {
      body: JSON.stringify({ template, options: { printBackground: true } }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  } else {
    response = await fetch(`${config.PRINTABLE_URL}/pdf/from/url`, {
      body: JSON.stringify({ url: url || window.location.href }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  }

  const blob = await response.blob();
  saveAs(blob, filename);
  return true;
};

export function getStatusColor(status, theme) {
  // TODO: Use status display for assessments
  switch (status) {
    case ASSESSMENT_STATUS.EXPIRED:
    case ASSESSMENT_STATUS.PI_REVISE:
      return theme.colors.red[800];
    case ASSESSMENT_STATUS.COMPLETED:
      return theme.colors.green[800];
    case ASSESSMENT_STATUS.DRAFT:
    default:
      return theme.colors.grey[400];
  }
}
export const getDisplayName = (label) => {
  switch (toUpper(label)) {
    case PPE_COORDINATOR:
      return 'PPE Coordinator';
    case IBC_MEMBER:
      return 'IBC Member';
    case NFPA_ADMIN:
      return 'NFPA Admin';
    case BSAS_ADMIN:
      return 'BSAS Admin';
    case CLI_ADMIN:
      return 'CLI Admin';
    case EHS_DEPARTMENT_ADMIN:
      return 'EHS Department Admin';
    case UNIT_RISK:
      return 'Unit Risk Assessment';
    case LAB_HAZARD:
      return 'Lab Hazard Assessment';
    case BUA:
      return BUA;
    case LOTO_PROCEDURES_TEMPLATE_ADMIN:
      return 'LOTO Procedures Template Admin';
    case SPH_PROCEDURES_TEMPLATE_ADMIN:
      return 'SPH Procedures Template Admin';
    case WPVP_PROCEDURES_TEMPLATE_ADMIN:
      return 'WPVP Procedures Template Admin';
    default:
      if (label && label.includes('AX_') && ANALYTICS_ROLE.EDGE_ROLES[label]) {
        return ANALYTICS_ROLE.EDGE_ROLES[label];
      }
      return startCase(toLower(label));
  }
};

export const getRolesbasedOnLabel = (nodeLabel) => {
  const label = toUpper(nodeLabel);
  return get(profileConfig, `${label}.roles`, []);
};

export const getSortedUsages = (usages, currentUsage) => {
  const sortedUsages = sortBy(usages, ['node.label', 'node.name']);
  return currentUsage ? [currentUsage].concat(pull(sortedUsages, currentUsage)) : sortedUsages;
};

export const camelcase = (input) => {
  const string = input || '';
  return string
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/^(.)|\s(.)/g, ($1) => {
      return $1.toUpperCase();
    });
};

export const roleHasPermission = (rolesWithAccess, relationships, targetId, nodeLabel, parentId, usages = null) => {
  return rolesWithAccess.some((roleWithAccess) =>
    relationships.some((r) => {
      const hasAccessToTarget =
        ((targetId && get(r, 'node.id') === targetId) || get(r, 'node.id') === parentId) &&
        get(r, 'edge.label') === roleWithAccess.role &&
        get(roleWithAccess, 'to.label') === nodeLabel;
      const hasAdminAccess =
        !hasAccessToTarget && get(roleWithAccess, 'to.label') === null && get(r, 'edge.label') === roleWithAccess.role;
      const hasAccessToTargetChild =
        !hasAdminAccess &&
        (usages || []).some(
          ({ node }) =>
            node.id === r.node.id && r.edge.label === roleWithAccess.role && r.node.label === roleWithAccess.to.label,
        );
      return hasAccessToTarget || hasAdminAccess || hasAccessToTargetChild;
    }),
  );
};

export const hasAdminAccessToLabel = (rolePermissions, relationships, nodeLabel, targetId = null) => {
  const label = toUpper(nodeLabel);
  const rolesWithAdminAccess = get(rolePermissions, `${label}.ADMIN`, []);
  return roleHasPermission(rolesWithAdminAccess, relationships, targetId, label);
};

export const getAccessRolesBasedOnLabel = (rolePermissions, label, targetId = null, readAccess) => {
  const allowedRolesForLabel = get(rolePermissions, `${label}.ALLOWED_ROLES`);
  const allowedRolesAccess =
    (targetId &&
      Object.values(allowedRolesForLabel || []).flatMap((roleAccess) =>
        Object.values(roleAccess).flatMap((role) => role),
      )) ||
    [];
  const readAccessRoles = readAccess ? get(rolePermissions, `${label}.READ`, []) : [];
  const rolesWithAccess = uniqWith(
    [
      ...readAccessRoles,
      ...get(rolePermissions, `${label}.ADMIN`, []),
      ...get(rolePermissions, `${label}.WRITE`, []),
      ...allowedRolesAccess,
    ],
    isEqual,
  );
  return { rolesWithAccess, allowedRolesForLabel };
};

export const hasReadAccessToTarget = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
) => {
  const label = toUpper(nodeLabel);
  const { rolesWithAccess } = getAccessRolesBasedOnLabel(rolePermissions, label, targetId, true);
  const hasAccessToParentOrTarget = roleHasPermission(
    rolesWithAccess,
    relationships,
    targetId,
    label,
    parentId,
    usages,
  );
  return hasAccessToParentOrTarget;
};

export const hasWriteAccessToNode = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
  targetRole = null,
) => {
  const label = toUpper(nodeLabel);
  const { allowedRolesForLabel, rolesWithAccess } = getAccessRolesBasedOnLabel(rolePermissions, label, targetId, false);

  const targetRoleWithWriteAccess =
    targetRole &&
    get(allowedRolesForLabel || {}, `${targetRole}.WRITE`, []).some((access) => {
      return relationships.some((rel) => get(rel, 'edge.label') === access.role);
    });

  const hasAccessToParentOrTarget = roleHasPermission(
    rolesWithAccess,
    relationships,
    targetId,
    label,
    parentId,
    usages,
  );
  return targetRole ? targetRoleWithWriteAccess : hasAccessToParentOrTarget;
};

export const filterOrganizationsByMetaData = (node) => {
  return get(profileConfig, `${node.label}.filterMetaData`)
    ? Object.entries(get(profileConfig, `${node.label}.filterMetaData`)).some(([key, value]) => {
        let response;
        if (Array.isArray(value)) {
          response =
            node[key] &&
            (!node[key].length ||
              node[key].some((k) => get(profileConfig, `${node.label}.filterMetaData.${key}`).includes(toUpper(k))));
        } else {
          response = node[key] === get(profileConfig, `${node.label}.filterMetaData.${key}`);
        }
        return response;
      })
    : true;
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export function parseToDisplayDate(date, defaultValue) {
  return moment(date).isValid() ? moment(date).format(DATE_FORMAT) : defaultValue || date;
}

export const getLoggedInUser = () => {
  const item = window.localStorage.getItem('RSS:USER') ?? {};
  return JSON.parse(item);
};

export const hasImpersonateAccess = () => {
  const user = getLoggedInUser();

  return Boolean(user.rss);
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const sheetFilesReader = async (files, parserOptions) => {
  if (!files) {
    return null;
  }

  const headers = await getHeaders();
  const formData = new FormData();
  files.map((file, index) => formData.append(`file${index}`, file));
  formData.append('options', JSON.stringify(parserOptions));

  const data = await fetch(`${config.PRINTABLE_URL}/xlsx/to/json`, {
    body: formData,
    headers,
    method: 'POST',
  });

  const result = await data.json();
  return result;
};

export const exportToExcel = async (filename, sheets, options = {}) => {
  const response = await fetchData(`${config.PRINTABLE_URL}/xlsx/from/json`, {
    body: JSON.stringify({ filename, data: sheets, options }),
    method: 'POST',
  });

  const blob = await response.blob();
  saveAs(blob, filename);
};

export const canViewOrganization = (features, label) => {
  const nodeLabel = toUpper(label);
  if (!Object.keys(NODE).includes(nodeLabel)) {
    return false;
  }
  switch (nodeLabel) {
    case NODE.GROUP:
      return get(features, 'organization.showGroups.value', true);
    case NODE.PROGRAM:
      return get(features, 'organization.showPrograms.value', true);
    default:
      return true;
  }
};

export const isExternalRole = (type) => !type || type === 'INTERNAL-EXTERNAL';

export const isFeatureSettingOn = (features = [], featureSettings) =>
  features.some((feature) => get(featureSettings, `${feature}.showFeature.value`, false));

export const hasCampusLevelAdminRole = ({ campusCode, relationships, featureSettings, rolePermissions }) =>
  Object.entries(get(rolePermissions, 'CAMPUS.ALLOWED_ROLES', {})).some(
    ([key, value]) =>
      isExternalRole(value.TYPE) &&
      isFeatureSettingOn(value.FEATURES, featureSettings) &&
      RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, key),
  );

export const mapYupValidationErrorToErrorMap = ({ inner }) => {
  return inner.reduce((acc, err) => {
    return { ...acc, [err.path]: [...(acc[err.path] || []), err.message] };
  }, {});
};

export const inlineErrorMessages = (errorString) => {
  const errorStrings = Array.isArray(errorString) ? errorString : [errorString];
  const errorMsg = errorStrings.filter(Boolean).reduce((acc, curr) => {
    if (typeof curr === 'string') {
      const msg = /\.\s*$/.test(curr) ? curr.trim() : curr.trim().concat('.');
      return [acc, msg];
    }

    return [acc, curr];
  }, []);

  return errorMsg;
};

export const convertToSingular = (type) => (type || '').slice(0, -1);

export const getParentBasedOnType = (roleType, tenantCode, campusCode) => {
  switch (toUpper(roleType)) {
    case NODE.GLOBAL:
      return { id: NODE.GLOBAL, label: NODE.GLOBAL };
    case NODE.TENANT:
      return { id: tenantCode, label: NODE.TENANT };
    case NODE.CAMPUS:
    default:
      return { id: campusCode, label: NODE.CAMPUS };
  }
};

export const isTenantsAPIEnabled = (user) => get(user, 'features.[client-config].useTenantsAPI.value');

export const getImpersonateId = () => cookie.parse(document.cookie)[config.SPOOF_USER_KEY] || null;
